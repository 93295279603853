import React from "react"
import {Link} from "gatsby"
import Style from "../styles/header.module.css"
import JsonData from "../../content/class_name.json"

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <header className={Style.header}>
                <div className={Style.top}>
                    <div className={Style.topBox}>
                        <h1 className={Style.siteTitle}><a href="/" >123小故事</a></h1>
                    </div>
                </div>
                <nav>
                    <ul className={Style.nav} >
                    <li className={this.props.nav === 'index' ? Style.active:""}><Link to="/">首页</Link></li>
                        {
                            JsonData.map((val, index) => (
                                <li className={this.props.nav === `class_${index + 1}` ? Style.active:""} key={index}><Link to={`/class/${index + 1}/`}>{val.name}</Link></li>
                            ))
                        }
                    </ul>
                </nav>
            </header>
        )
    }
}

export default Header