import React from "react"
import {Link} from "gatsby"
import Style from "../styles/item.module.css"

export default (props) => (
    <section className={Style.box}>
        <h2>{props.titleUrl ? <Link style={{color: `#0077ff`}} to={props.titleUrl}>{props.obj.class_name}</Link>:props.obj.class_name}</h2>
        <ul>
            {
                props.obj.data.map((val, index) => (
                <li key={index}><Link to={`/story/${val.id}/`}>{val.title}</Link></li>
                ))
            }
        </ul>
    </section>
)